import { useNavigate } from "@remix-run/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { apiClient, getFrontendUrl } from "~/lib/client";
import { ApiTokenResponse } from "~/routes/api+/token";
import { RegisterRequest, TokenSchema } from "~/types/backend";
import {
  getAccessTokenQuery,
  getCurrentUser,
  getPwdRestInfo,
  getUserList,
} from "./auth-querries";

export function useAccessToken() {
  const { data, isFetched, isError, error } = useQuery(getAccessTokenQuery());
  if (isError) {
    console.error("Error fetching access token", error);
    return "";
  }
  if (isFetched && data?.access_token === undefined) {
    console.error("Error fetching access token (no access token in response)");
    return "";
  }
  return data?.access_token;
}

export function useLogout() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return async () => {
    await axios.post(`${getFrontendUrl()}/api/logout`);
    navigate("/");
    queryClient.invalidateQueries({
      queryKey: ["access_token"],
    });
  };
}

export function useGetCurrentUser() {
  const accessToken = useAccessToken();
  return useQuery({ ...getCurrentUser(accessToken), retry: false });
}

export function useGetCurrentUserRoleIDs() {
  const { data: user, isError } = useGetCurrentUser();
  if (isError) {
    return [];
  }
  return user?.roles.map((r) => r.uuid) || [];
}

export const ROLE_IDS = ["role_admin"] as const;
export type TRole = (typeof ROLE_IDS)[number];

export function useHasRole(role: TRole | TRole[]) {
  const { data: user, isError } = useGetCurrentUser();
  if (isError) {
    return false;
  }
  if (!user) {
    return undefined;
  }
  const roleIDs = user.roles.map((r) => r.uuid);
  if (Array.isArray(role)) {
    return role.some((r) => roleIDs.includes(r));
  }
  return roleIDs.includes(role);
}

export function useGetUserList() {
  const accessToken = useAccessToken();
  return useQuery({ ...getUserList(accessToken), retry: false });
}

export function useRequestOtp() {
  return useMutation({
    mutationFn: async (email: string) => {
      await apiClient.post("/auth/otp/request?email=" + email);
    },
  });
}

export function useRegisterWithPassword() {
  return useMutation<void, AxiosError, RegisterRequest>({
    mutationFn: async (data: RegisterRequest) => {
      await apiClient.post("/auth/register", data);
    },
  });
}

type loginWithPasswordRequest = {
  email: string;
  password: string;
};

export function useLoginWithPassword(onSuccess?: () => void) {
  const queryClient = useQueryClient();
  return useMutation<TokenSchema, AxiosError, loginWithPasswordRequest>({
    mutationFn: async (data: loginWithPasswordRequest) => {
      const formData = new FormData();
      formData.append("username", data.email);
      formData.append("password", data.password);

      const response = await axios.post<TokenSchema>(
        `${getFrontendUrl()}/api/login-with-password`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["access_token"], {
        access_token: data.access_token,
      } satisfies ApiTokenResponse);
      if (onSuccess) {
        onSuccess();
      }
    },
  });
}

export function useGetPwdResetInfo(reset_token: string | undefined) {
  return useQuery({ ...getPwdRestInfo(reset_token), retry: false });
}

export function useRequestPasswordReset() {
  return useMutation({
    mutationFn: async (email: string) => {
      await apiClient.post("/auth/pwdreset/request", { email });
    },
  });
}

export function useResetPassword() {
  return useMutation({
    mutationFn: async (data: { reset_token: string; password: string }) => {
      await apiClient.post(`/auth/pwdreset/reset/${data.reset_token}`, {
        password: data.password,
      });
    },
  });
}
