import { getFrontendUrl, queryFn } from "~/lib/client";
import { ApiTokenResponse } from "~/routes/api+/token";
import {
  PwdResetInfoSchema,
  UserListResponse,
  UserSchema,
} from "~/types/backend";

export function getCurrentUser(access_token: string | undefined) {
  return {
    queryKey: ["current_user"],
    queryFn: queryFn<UserSchema>("GET", `/auth/me`, access_token),
    enabled: access_token !== undefined,
  };
}
export function getAccessTokenQuery() {
  return {
    queryKey: ["access_token"],
    queryFn: async (): Promise<ApiTokenResponse> =>
      fetch(`${getFrontendUrl()}/api/token`).then((res) => res.json()),
    staleTime: 1000 * 60 * 60,
  };
}

export function getUserList(access_token: string | undefined = "") {
  return {
    queryKey: ["user_list"],
    queryFn: queryFn<UserListResponse>("GET", `/auth/users`, access_token),
    enabled: access_token !== undefined,
  };
}

export function getPwdRestInfo(reset_token: string | undefined) {
  return {
    queryKey: ["pwd_reset_info"],
    queryFn: queryFn<PwdResetInfoSchema>(
      "GET",
      `/auth/pwdreset/info/${reset_token}`
    ),
    enabled: reset_token !== undefined,
  };
}
